import React, { Component } from 'react';

class Derecho extends Component {
  render() {

    return (
      <section id="derecho">
                                                    
      <div className="row education">
            <h2>Derecho</h2>
      </div>
                                                    
      <div className="row education">
         <div className="three columns header-col">
            <h1><span>Servicios</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 En Jiménez Miketta Abogados & Consultores atendemos cada caso de forma personalizada, con profesionales especializados en distintas ramas del Derecho, a costos accesibles.
               </div>
            </div>
         </div>
      </div>
                                                    
      <div className="row education">
         <div className="three columns header-col">
            <h1><span>DERECHO CIVIL</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 Prestamos servicios de representación legal en temas de propiedad y posesión, obligaciones contractuales, relaciones familiares tales como matrimonio, patria potestad, etc.; herencia y divorcio.
               </div>
            </div>
         </div>
      </div>

      <div className="row education">
         <div className="three columns header-col">
            <h1>PROPIEDAD <span>INTELECTUAL</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 Tenemos una amplia experiencia brindando asesoría especializada a nuestros clientes para obtener rápidamente la protección efectiva de sus derechos de autor, registro de sus marcas comerciales, signos distintivos y patentes.
               </div>
            </div>
         </div>
      </div>
                                                    
      <div className="row education">
         <div className="three columns header-col">
            <h1>CONTRATACIÓN <span>PÚBLICA</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 Ofrecemos servicios especializados para todas las etapas de la contratación pública como son la precontractual y contractual, hasta el cierre de los contratos administrativos.
               </div>
            </div>
         </div>
      </div>
                                                    
      <div className="row education">
         <div className="three columns header-col">
            <h1><span>DERECHO SOCIETARIO</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 Brindamos asesoría integral a pequeñas, medianas y grandes empresas. Ofrecemos un acompañamiento personalizado para constituir la sociedad que mejor se ajuste a sus necesidades. Elaboramos todo tipo de documentación corporativa.
               </div>
            </div>
         </div>
      </div>
                                                    
      <div className="row education">
         <div className="three columns header-col">
            <h1><span>DERECHO LABORAL</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 Somos especialistas en asuntos de contratación laboral, prevención de riesgos o litigios laborales, relaciones individuales y colectivas de trabajo.
               </div>
            </div>
         </div>
      </div>
                                                    
      <div className="row education">
         <div className="three columns header-col">
            <h1>DERECHO <span>TRIBUTARIO</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 Brindamos asesoría tributaria tanto a personas naturales como jurídicas, para ello contamos con un equipo multidisciplinario de profesionales especializados en materia fiscal.
               </div>
            </div>
         </div>
      </div>
                                                    
      <div className="row education">
         <div className="three columns header-col">
            <h1><span>DERECHO PENAL</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 Realizamos la defensa integral en todas las etapas del proceso penal que soliciten nuestros clientes. Atendemos casos de delitos flagrantes y casos desde la etapa de indagación previa hasta el último recurso que sea procedente. 
               </div>
            </div>
         </div>
      </div>
                                                    
      <div className="row education">
         <div className="three columns header-col">
            <h1>MEDIACIÓN Y <span>ARBITRAJE</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 Somos mediadores certificados.
               </div>
            </div>
         </div>
      </div>

   </section>
    );
  }
}

export default Derecho;
