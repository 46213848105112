import React, { Component } from 'react';

class Nuestra_firma extends Component {
  render() {
      
      var bannerpic= "images/bannerpic.jpg";
      var ethelpic= "images/ethelpic.jpg";
      var wilfridopic= "images/wilfridopic.jpg";

    if(this.props.data){
      var testimonials = this.props.data.testimonials.map(function(testimonials){
        return  <li key={testimonials.user}>
            <blockquote>
               <p>{testimonials.text}</p>
               <cite>{testimonials.user}</cite>
            </blockquote>
         </li>
      });
      var profilepic= "images/profilepic.jpg";
    }

    return (
      <section id="nuestra_firma">
        <div className="row education">

            <div className="twelve columns header-col">
               <h1><span>Nuestra Firma</span></h1>
            </div>
        </div>
        <div className="row education">
               <center><img className="banner-pic"  src={bannerpic} alt="Jimenez Miketta" /></center>
        </div>
      <div className="text-container">
        <div className="row education">

            <div className="three columns">
                <center><img className="profile-pic"  src={ethelpic} alt="Jimenez Miketta" /></center>
             </div>

            <div className="nine columns flex-container">
                        <h3>Abg. Ethel Jiménez Miketta</h3>
                        <p>Abogada de los Tribunales y Juzgados de la República del Ecuador, Tallerista y Mediadora. Profesional con más de 10 años de experiencia en Derecho de la Familia, Derecho Laboral, Derecho Civil, Propiedad Intelectual y Derechos de Autor.</p>
                        <p><b>Matrícula profesional:</b> 17-2009-84 Foro de Abogados CNJ</p>
               </div>
            </div>
        
        <div className="row education">

            <div className="three columns">
                &nbsp;
             </div>

            <div className="nine columns flex-container">
                       <h3>Dr. Edwin Jiménez Miketta</h3>
                        <p>Doctor en Jurisprudencia y Abogado de los Tribunales y Juzgados de la República del Ecuador, Procurador Síndico y Docente. Profesional con más de 20 años de experiencia en Contratación Pública, Derecho Tributario, Derecho Laboral, Derecho Civil y Derecho Municipal.</p>
                        <p><b>Matrícula profesional:</b> 442 Colegio de Abogados de Esmeraldas CNJ</p>
               </div>
            </div>
        
        <div className="row education">

            <div className="three columns">
                <center><img className="profile-pic"  src={wilfridopic} alt="Jimenez Miketta" /></center>
             </div>

            <div className="nine columns flex-container">
                       <h3>Dr. David Wilfrido Fiallo Bermúdez</h3>
                        <p>Magister Derecho procesal - Comisionado de Sudamérica Derechos Humanos. Director Ejecutivo del Área Penal de la Firma Legal Abogados Jiménez Miketta</p>
                        <p>Tipo de Delitos: Robo, Hurto, Fraude Procesal, Abuso de Menores, Falsificación Uso de Documentos Públicos, Tránsito, Estafa, Abuso de Confianza, Incidente de Rebaja de Pensión de Alimentos, Boleta de Auxilio, Medidas Cautelares VIF, Defraudación Tributaria, Infracciones por Violencia de Género, Tentativa de Homicidio, Acción Penal Pública, Ejercicio Ilegal de la Profesión de Abogado, Lesiones en Tránsito, Choque con Daños, Muerte culposa en Tránsito, Robo de vehículos.</p>
               </div>
            </div>
        
        <div className="row education">

            <div className="three columns">
                &nbsp;
             </div>

            <div className="nine columns flex-container">
                        <h3>Estudio Jurídico Taco & Jiménez Asociados</h3>
                        <p>En la provincia de Esmeraldas, estamos asociados con el Estudio Jurídico Taco & Jiménez Asociados. Atendemos asuntos:</p>
                        <ul>
                            <li>Civiles</li>
                            <li>Penales</li>
                            <li>Tránsito</li>
                            <li>Tributarios</li>
                            <li>Contratación pública</li>
                            <li>Mediación y Arbitraje</li>
                            <li>Societario</li>
                            <li>Municipal</li>
                            <li>Medio Ambiente</li>
                        </ul>
                        <p>Estamos situados en la ciudad y cantón de Atacames, sector Cocobamba, en la Vía principal frente a los edificios municipales. Atacames - Tonsupa.</p>
               </div>
            </div>
         </div>
                            
   </section>
    );
  }
}

export default Nuestra_firma;
