import React, { Component } from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import { NavLink, Switch, Route } from 'react-router-dom';
import Header from '../Components/Header';
import Derecho from '../Components/Derecho';
import Contacto from '../Components/Contacto';
import Nuestra_firma from '../Components/Nuestra_firma';
import Clientes from '../Components/Clientes';

class Home extends Component {
    
    constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      resumeData: {}
    };

    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);

  }

  getResumeData(){
    $.ajax({
      url:'/resumeData.json',
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({resumeData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    this.getResumeData();
  }
    
  render() {
    return (
      <div className='home'>
        <Header data={this.state.resumeData.main}/>
        <Derecho />
        <Nuestra_firma />
        <Clientes />
        <Contacto data={this.state.resumeData.main}/>
      </div>
    );
  }
}

export default Home;
