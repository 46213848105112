import React, { Component } from 'react';
import Navigation from '../Navigation';

class Tarifario extends Component {
  render() {

    return (
      <section id="tarifario">
      <Navigation data={this.props.data} />
        <div className="row education">
            <h2>Tarifario</h2>
      </div>
      
                                                    
      <div className="row education">
      <table>
<tbody>
<tr>
<td>
<p><strong>SERVICIOS</strong></p>
</td>
<td>
<p><strong>HONORARIOS</strong></p>
</td>
<td>
<p><strong>TASAS</strong></p>
</td>
</tr>
<tr>
<td>
<p>Registro de marca, incluyendo env&iacute;o del t&iacute;tulo</p>
</td>
<td>
<p>US$ 544,00</p>
</td>
<td>
<p>US$ 208,00</p>
</td>
</tr>
<tr>
<td>
<p>Registro de marca tridimensional, incluyendo env&iacute;o del t&iacute;tulo</p>
</td>
<td>
<p>US$ 544,00</p>
</td>
<td>
<p>US$ 1.002,00</p>
</td>
</tr>
<tr>
<td>
<p>Registro de nombre comercial</p>
</td>
<td>
<p>US$ 544,00</p>
</td>
<td>
<p>US$ 208,00</p>
</td>
</tr>
<tr>
<td>
<p>Registro de lema comercial</p>
</td>
<td>
<p>US$ 544,00</p>
</td>
<td>
<p>US$ 208,00</p>
</td>
</tr>
<tr>
<td>
<p>Renovaci&oacute;n de marca, incluyendo el env&iacute;o de t&iacute;tulo</p>
</td>
<td>
<p>US$ 414,00</p>
</td>
<td>
<p>US$ 208,00</p>
</td>
</tr>
<tr>
<td>
<p>Renovaci&oacute;n dentro del per&iacute;odo de gracia, incluyendo el env&iacute;o</p>
<p>del t&iacute;tulo</p>
</td>
<td>
<p>US$ 484,00</p>
</td>
<td>
<p>US$ 208,00</p>
</td>
</tr>
<tr>
<td>
<p>Registro de transferencia, fusi&oacute;n, cambio de nombre y domicilio de marcas, incluyendo el env&iacute;o del t&iacute;tulo</p>
</td>
<td>
<p>US$ 244,00</p>
</td>
<td>
<p>US$ 101,00</p>
</td>
</tr>
<tr>
<td>
<p>B&uacute;squeda de marca en una clase</p>
</td>
<td>
<p>US$ 150,00</p>
</td>
<td>
<p>US$ 16,00</p>
</td>
</tr>
<tr>
<td>
<p>B&uacute;squeda de marca por cada clase adicional a la primera</p>
</td>
<td>
<p>US$ 100,00</p>
</td>
<td>
<p>US$ 16,00</p>
</td>
</tr>
<tr>
<td>
<p>Investigaci&oacute;n por propietario (m&iacute;nimo)</p>
<p>adicional por cada marca encontrada</p>
</td>
<td>
<p>US$ 150,00</p>
</td>
<td>
<p>$ 16,00</p>
<p>$ 2,00</p>
</td>
</tr>
<tr>
<td>
<p>Renuncia de derechos</p>
</td>
<td>
<p>US$ 244,00</p>
</td>
<td>
<p>US$ 101,00</p>
</td>
</tr>
<tr>
<td colspan="3">
<p><strong>OPOSICIONES A REGISTRO DE SIGNOS DISTINTIVOS</strong></p>
</td>
</tr>
<tr>
<td>
<p>Presentaci&oacute;n de oposici&oacute;n</p>
</td>
<td>
<p>US$ 550,00</p>
</td>
<td>
<p>US$ 140,00</p>
</td>
</tr>
<tr>
<td>
<p>Contestaci&oacute;n de oposici&oacute;n</p>
</td>
<td>
<p>US$ 550,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Presentaci&oacute;n de escrito de alerta</p>
</td>
<td>
<p>US$ 550,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Contestaci&oacute;n de oposici&oacute;n y presentaci&oacute;n simult&aacute;nea de Acci&oacute;n de Cancelaci&oacute;n</p>
</td>
<td>
<p>US$ 1.250,00</p>
</td>
<td>
<p>US$ 430,00</p>
</td>
</tr>
<tr>
<td>
<p>Presentaci&oacute;n de alegato/pruebas en Oposici&oacute;n</p>
</td>
<td>
<p>US$ 300,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Negociaci&oacute;n y presentaci&oacute;n de Acuerdo Transaccional</p>
</td>
<td>
<p>US$ 400,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Desistimiento de la Oposici&oacute;n</p>
</td>
<td>
<p>US$ 130,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Reporte de &eacute;xito (si la resoluci&oacute;n es favorable)</p>
</td>
<td>
<p>US$ 200,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Oposici&oacute;n Andina y presentaci&oacute;n simult&aacute;nea de solicitud en Ecuador</p>
</td>
<td>
<p>US$ 952,00</p>
</td>
<td>
<p>US$ 348,00</p>
</td>
</tr>
<tr>
<td>
<p>Recurso Administrativo de Reposici&oacute;n</p>
</td>
<td>
<p>US$ 1.330,00</p>
</td>
<td>
<p>US$ 170,00</p>
</td>
</tr>
<tr>
<td>
<p>Contestaci&oacute;n a Recurso Administrativo de Reposici&oacute;n</p>
</td>
<td>
<p>US$ 1.330,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Recurso Administrativo de Apelaci&oacute;n</p>
</td>
<td>
<p>US$ 1.440,00</p>
</td>
<td>
<p>US$ 272,00</p>
</td>
</tr>
<tr>
<td>
<p>Contestaci&oacute;n a Recurso Administrativo de Apelaci&oacute;n</p>
</td>
<td>
<p>US$ 1.440,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Recurso Administrativo de Revisi&oacute;n</p>
</td>
<td>
<p>US$ 1.440,00</p>
</td>
<td>
<p>US$ 272,00</p>
</td>
</tr>
<tr>
<td>
<p>Contestaci&oacute;n a Recurso Administrativo de Revisi&oacute;n</p>
</td>
<td>
<p>US$ 1.440,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Reporte de &eacute;xito (si la resoluci&oacute;n es favorable)</p>
</td>
<td>
<p>US$ 350,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Escrito de prueba en recurso administrativo y/o alegato</p>
</td>
<td>
<p>US$ 350,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Comparecencia a audiencia y/o alegato ﬁnal en recurso administrativo</p>
</td>
<td>
<p>US$ 350,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td colspan="3">
<p><strong>ACCIONES DE CANCELACION DE REGISTRO</strong></p>
</td>
</tr>
<tr>
<td>
<p>Presentaci&oacute;n Acci&oacute;n Cancelaci&oacute;n</p>
</td>
<td>
<p>US$ 1.020,00</p>
</td>
<td>
<p>US$ 430,00</p>
</td>
</tr>
<tr>
<td>
<p>Contestaci&oacute;n a Acci&oacute;n de Cancelaci&oacute;n</p>
</td>
<td>
<p>US$ 1.440,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Reporte de &eacute;xito (si la resoluci&oacute;n es favorable)</p>
</td>
<td>
<p>US$ 350,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Escrito de prueba en acci&oacute;n de cancelaci&oacute;n y/o alegato</p>
</td>
<td>
<p>US$ 350,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Comparecencia a audiencia y/o alegato ﬁnal en acci&oacute;n de cancelaci&oacute;n</p>
</td>
<td>
<p>US$ 350,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td colspan="3">
<p><strong>OBSERVANCIA: MEDIDAS CAUTELARES, TUTELA ADMINISTRATIVA</strong></p>
</td>
</tr>
<tr>
<td>
<p>Carta de Cese de Uso (m&iacute;nimo)</p>
</td>
<td>
<p>US$ 400,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Presentaci&oacute;n de Tutela Administrativa</p>
</td>
<td>
<p>US$ 1.515,00</p>
</td>
<td>
<p>US$ 208,00</p>
</td>
</tr>
<tr>
<td>
<p>Contestaci&oacute;n de Tutela Administrativa</p>
</td>
<td>
<p>US$ 1.515,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Demanda de medidas cautelares en v&iacute;a judicial</p>
</td>
<td>
<p>US$ 1.515,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Contestaci&oacute;n de demanda de medidas cautelares en v&iacute;a judicial</p>
</td>
<td>
<p>US$ 1.515,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Reporte de &eacute;xito (si la resoluci&oacute;n es favorable)</p>
</td>
<td>
<p>US$ 350,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Medida en Frontera</p>
</td>
<td>
<p>US$ 500,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td colspan="3">
<p><strong>JUICIO CONTENCIOSO ADMINISTRATIVO (IMPUGNACION JUDICIAL DE RESOLUCIONES ADMINISTRATIVAS)</strong></p>
</td>
</tr>
<tr>
<td>
<p>Demanda Contencioso Administrativa (m&iacute;nimo)</p>
</td>
<td>
<p>US$ 2.800,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Contestaci&oacute;n de Demanda Contencioso Administrativa (m&iacute;nimo)</p>
</td>
<td>
<p>US$ 2.800,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Presentaci&oacute;n de escrito de prueba en juicio</p>
</td>
<td>
<p>US$ 300,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Obtenci&oacute;n de Interpretaci&oacute;n Prejudicial en juicio</p>
</td>
<td>
<p>US$ 200,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Presentaci&oacute;n de alegato en juicio</p>
</td>
<td>
<p>US$ 300,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Honorario por mantenimiento anual de juicio</p>
</td>
<td>
<p>US$ 200,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Reporte de &eacute;xito (si la resoluci&oacute;n es favorable)</p>
</td>
<td>
<p>US$ 500,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Presentaci&oacute;n de recurso de casaci&oacute;n (m&iacute;nimo)</p>
</td>
<td>
<p>US$ 2.000,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Contestaci&oacute;n a recurso de casaci&oacute;n (m&iacute;nimo)</p>
</td>
<td>
<p>US$ 2.000,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td colspan="3">
<p><strong>INSCRIPCION DE CONTRATO DE LICENCIA DE USO</strong></p>
</td>
</tr>
<tr>
<td>
<p>Inscripci&oacute;n de Licencia de Uso (por primera marca)</p>
</td>
<td>
<p>US$ 300,00</p>
</td>
<td>
<p>US$ 72,00</p>
</td>
</tr>
<tr>
<td>
<p>Inscripci&oacute;n de Licencia de Uso desde la segunda marca</p>
</td>
<td>
<p>US$ 240,00</p>
</td>
<td>
<p>US$ 72,00</p>
</td>
</tr>
<tr>
<td>
<p>Acci&oacute;n de Nulidad de Registro de Marcas&nbsp;</p>
</td>
<td>
<p>250</p>
</td>
<td>
<p>72</p>
</td>
</tr>
<tr>
<td>
<p>Soluci&oacute;n Alternativa de Conflictos tanto en Derechos de Autor y Registro Marcario&nbsp;</p>
</td>
<td>
<p>250</p>
</td>
<td>
<p>72</p>
</td>
</tr>
<tr>
<td>
<p>Contratos de Confidencialidad y/o Secreto Empresarial&nbsp;</p>
</td>
<td>
<p>200</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Dep&oacute;sito de Informaci&oacute;n Confidencial o Secreto Empresarial&nbsp;</p>
</td>
<td>
<p>200</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Elaboraci&oacute;n de Contratos de Licencia de Imagen&nbsp;</p>
</td>
<td>
<p>200</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Protecci&oacute;n de la Imagen a trav&eacute;s de derechos de autor, derecho marcario y datos personales - habeas data&nbsp;</p>
</td>
<td>
<p>200</p>
</td>
<td>
</td>
</tr>
<tr>
<td colspan="3">
<p><strong>INSCRIPCION DE OBRAS U OBJETOS PROTEGIDOS POR DERECHO DE AUTOR</strong></p>
</td>
</tr>
<tr>
<td>
<p>Inscripci&oacute;n de Derechos de Autor</p>
</td>
<td>
<p>US$ 220,00</p>
</td>
<td>
<p>12 / 20</p>
</td>
</tr>
<tr>
<td>
<p><strong>SERVICIO</strong></p>
</td>
<td>
<p><strong>HONORARIOS(US$)</strong></p>
</td>
<td>
<p><strong>TASAS(US$)</strong></p>
</td>
</tr>
<tr>
<td>
<p>Solicitud de Patente de Invenci&oacute;n</p>
</td>
<td>
<p>US$ 1.050,00</p>
</td>
<td>
<p>US$ 495,33</p>
</td>
</tr>
<tr>
<td>
<p>Solicitud de Modelo de Utilidad</p>
</td>
<td>
<p>US$ 946,00</p>
</td>
<td>
<p>US$ 136,00</p>
</td>
</tr>
<tr>
<td>
<p>Solicitud de Dise&ntilde;o Industrial</p>
</td>
<td>
<p>US$ 946,00</p>
</td>
<td>
<p>US$ 526,46</p>
</td>
</tr>
<tr>
<td>
<p>Transferencia, fusi&oacute;n, cambio de nombre y domicilio</p>
</td>
<td>
<p>US$ 356,00</p>
</td>
<td>
<p>US$ 227,25</p>
</td>
</tr>
<tr>
<td>
<p>Contestaci&oacute;n a oposici&oacute;n</p>
</td>
<td>
<p>US$ 950,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Enmiendas</p>
</td>
<td>
<p>US$ 180,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Desistimiento</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Impugnaci&oacute;n a ex&aacute;menes de patentabilidad (m&iacute;nimo)</p>
</td>
<td>
<p>US$ 600,00</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Honorarios de Perito</p>
</td>
<td>
<p>US$ 150,00</p>
</td>
<td>
<p>$ 596,49 y 10% adicional por cada p&aacute;gina a partir de la 20</p>
</td>
</tr>
<tr>
<td colspan="3">
<p><strong>MANTENIMIENTO</strong></p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 1</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 125,00</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 2</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 145,00</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 3</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 168,20</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 4</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 195,11</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 5</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 226,33</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 6</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 262,54</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 7</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 304,55</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 8</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 353,28</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 9</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 409,80</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 10</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 475,37</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 11</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 551,43</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 12</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 639,66</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 13</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 742,00</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 14</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 860,72</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 15</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 998,44</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 16</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 1.158,19</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 17</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 1.343,50</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 18</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 1.558,46</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 19</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 1.807,81</p>
</td>
</tr>
<tr>
<td>
<p>Mantenimiento a&ntilde;o 20</p>
</td>
<td>
<p>US$ 250,00</p>
</td>
<td>
<p>US$ 2.097,06</p>
</td>
</tr>
<tr>
<td>
<p>Reivindicaci&oacute;n adicional luego de la d&eacute;cima</p>
</td>
<td>
<p>$ 55,07 cada una</p>
</td>
<td>
<p>$ 55,07 cada una</p>
</td>
</tr>
<tr>
<td>
<p>Busqueda de Antecedentes de Dise&ntilde;os Industriales, Cambios de Nombre, Transferencia o cesi&oacute;n de Derechos&nbsp;</p>
</td>
<td>
<p>200</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Oposici&oacute;n de Registro de Dise&ntilde;os Industriales&nbsp;</p>
</td>
<td>
<p>250</p>
</td>
<td>
</td>
</tr>

<tr>
<td>
<p><strong>BORRAR RECORDS POLICIALES</strong></p>
</td>
<td>
<p>350</p>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p><strong>Requisitos:</strong></p>
</td>
<td>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Copia certificada del parte de detención emitido por el Juzgado o Tribunal que conoce la causa.</p>
</td>
<td>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Copia certificada de la resolución ejecutoriada, si fue sentenciado a razón del cumplimiento de la pena.</p>
</td>
<td>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Certificado de antecedentes judiciales penales, esta información será bajada de la página de la Función Judicial en cumplimiento a la resolución 318 del 10 de Octubre del 2015.</p>
</td>
<td>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Prontuario penitenciario emitido por el centro de privación de libertad.</p>
</td>
<td>
</td>
<td>
</td>
</tr>
<tr>
<td>
<p>Copia de la cédula y certificado de votación a color.</p>
</td>
<td>
</td>
<td>
</td>
</tr>
</tbody>
</table>
        </div>
   </section>
    );
  }
}

export default Tarifario;
