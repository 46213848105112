import React, { Component } from 'react';
import Navigation from '../Navigation';

class Ip extends Component {
  render() {

    if(this.props.data){
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function(work){
        return <div key={work.company}><h3>{work.company}</h3>
            <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
            <p>{work.description}</p>
        </div>
      })
      var skills = this.props.data.skills.map(function(skills){
        var className = 'bar-expand '+skills.name.toLowerCase();
        return <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name}</em></li>
      })
    }

    return (
      <section id="ip">
      <Navigation data={this.props.data} />
        <div className="row education">
            <h2>Portafolio de Servicios en Propiedad Intelectual</h2>
      </div>
      
                                                    
<div className="row education">
<p>La <strong>MARCA </strong>es un signo distintivo que permite diferenciar los productos y/o servicios de una empresa, instituci&oacute;n u organizaci&oacute;n de los que ofrece la competencia. Generalmente, las<strong> MARCAS</strong> est&aacute;n compuestas por palabras, letras, n&uacute;meros, dibujos, fotos, formas, colores, logotipos, etiquetas o una combinaci&oacute;n de estos elementos.</p>
<p>Sin embargo, a causa de un generalizado desconocimiento acerca del valor real asociado a una <strong>MARCA</strong>, <strong>PATENTE</strong> y/o <strong>R&Oacute;TULO COMERCIAL </strong>muchas personas, individuales o colectivas, terminan perdiendo dinero y posicionamiento en el mercado al no registrar debidamente sus signos distintivos.</p>
        <p>La posibilidad de que su <strong>MARCA</strong> sea registrada a nombre de terceras personas quienes inescrupulosamente se beneficiar&iacute;an de la imagen y el prestigio que usted logr&oacute; obtener como consecuencia de muchos a&ntilde;os de trabajo y esfuerzo para consolidarla en el mercado est&aacute; latente y al no poseer la leg&iacute;tima titularidad, es decir, el debido registro de <strong>MARCA, </strong>usted compromete el patrimonio intangible de su compa&ntilde;&iacute;a, expone la imagen de la misma a un eventual da&ntilde;o y pone en riesgo su propio futuro.</p>
        <p>A pesar de esto, hoy en d&iacute;a son cada vez m&aacute;s las organizaciones (empresa o instituci&oacute;n) que comprenden la importancia de registrar y proteger sus<strong> MARCAS, </strong>no s&oacute;lo en su pa&iacute;s de origen sino tambi&eacute;n en todos aquellos mercados internacionales donde realizan o planean llevar a cabo actividades. Es por ello que una <strong>MARCA </strong>viene a constituirse en una herramienta fundamental de la empresa pues le permite a &eacute;sta distinguir sus productos y/o servicios de otros existentes en el mercado.</p>
        <p><strong>REGISTRABILIDAD</strong></p>
        <p>En t&eacute;rminos generales, pueden registrarse como marcas una o m&aacute;s palabras, con o sin sentido conceptual, dibujos, emblemas, monogramas, sellos, im&aacute;genes, envoltorios, envases, combinaciones de colores, letras y n&uacute;meros que posean un dibujo o dise&ntilde;o en especial, frases publicitarias, relieves, etc., <strong>siempre y cuando tengan capacidad distintiva</strong>.</p>
        <p>Consecuentemente, no son registrables como marcas de productos y/o servicios:</p>
        <ul>
        <li>Palabras, nombres, dibujos, frases, etc., id&eacute;nticas o similares a otras exustentes en el mercado con anterioridad.</li>
        <li>Denominaciones de origen nacionales, extranjeras o aquellas que puedan inducir a errores con respecto a su naturaleza, propiedades, calidad, etc.</li>
        <li>Palabras, dibujos y dem&aacute;s signos contrarios a la moral y las buenas costumbres.</li>
        <li>Designaciones, nombres, etc., los cuales sean descriptivos de la actividad que se desea proteger.</li>
        </ul>
        </div>
        
        <div className="row education">
        <h3>EFECTOS LEGALES Y VENTAJAS DEL REGISTRO DE MARCA</h3>
        <p>Una <strong>"MARCA REGISTRADA" </strong>otorga a su leg&iacute;timo titular derechos importantes sobre la misma, vale decir, le confiere la <strong>propiedad </strong>y <strong>exclusividad </strong>de uso, estando a salvo de terceras personas inescrupulosas que pretendieran emplearla o registrarla a su nombre para comercializar productos similares o id&eacute;nticos; asimismo, dicho titular podr&aacute; crear y lanzar nuevos productos y/o servicios teniendo la certeza de que su <strong>MARCA</strong> no tiene similitud con alguna ya registrada o v&aacute;lidamente solicitada.</p>
        <p>Las invenciones patentadas han invadido todos los aspectos de la vida humana, desde la luz el&eacute;ctrica (cuyas Patentes detentaban Edison y Swan) hasta el pl&aacute;stico (Baekeland), pasando por los bol&iacute;grafos (Bir&oacute;) y los microprocesadores (Intel), por ejemplo. Las Patentes y Modelos de Utilidad proporcionan no s&oacute;lo protecci&oacute;n para el titular sino asimismo informaci&oacute;n e inspiraci&oacute;n valiosa para las futuras generaciones de investigadores e inventores.</p>
        <p>Las Patentes y Modelos de Utilidad constituyen incentivos para las personas, ya que ofrece el reconocimiento por su creatividad y recompensas materiales por sus invenciones comercializables. Estos incentivos alientan la innovaci&oacute;n, que garantiza la mejora constante de la calidad de la vida humana.</p>
        <p>La <strong>PATENTE DE INVENCI&Oacute;N</strong> es un derecho exclusivo, concedido mediante un T&iacute;tulo de Propiedad, que protege en la pr&aacute;ctica toda invenci&oacute;n que ofrece una nueva manera de hacer algo, o una nueva soluci&oacute;n t&eacute;cnica a un problema, que posea <strong>APLICACI&Oacute;N INDUSTRIAL</strong>. La invenci&oacute;n debe ser Nueva en el sentido de que no ha sido publicada o utilizada p&uacute;blicamente a nivel nacional o internacional, no evidente y aplicable en la industria.</p>
        <p>El <strong>MODELO DE UTILIDAD</strong> es una creaci&oacute;n que pretende entregar una configuraci&oacute;n o forma tridimensional nueva a instrumentos, aparatos, herramientas, dispositivos y, en general, a objetos o partes de los mismos, conocidos y que se utilicen para realizar un trabajo pr&aacute;ctico, siempre que esta nueva configuraci&oacute;n produzca una mejor utilizaci&oacute;n del objeto en la funci&oacute;n a la que est&aacute; destinado.</p>
        <p><strong>REGISTRABILIDAD</strong></p>
        <p>Ser&aacute;n patentables las invenciones de productos o procedimientos, siempre que sean nuevas, entra&ntilde;en una actividad inventiva y sean susceptibles de aplicaci&oacute;n industrial:</p>
        <p>Se considerara invenci&oacute;n a toda creaci&oacute;n humana que permita transformar materia o energ&iacute;a para su aprovechamiento por el hombre.</p>
        <p>Ser&aacute; considerada novedosa toda invenci&oacute;n que no este comprendida en el estado de la t&eacute;cnica.</p>
        <p>Por estado de la t&eacute;cnica deber&aacute; entenderse el conjunto de conocimientos t&eacute;cnicos que se han hecho p&uacute;blicos antes de la fecha de presentaci&oacute;n de la solicitud de patente o, en su caso, de la prioridad reconocida, mediante una descripci&oacute;n oral o escrita, por la explotaci&oacute;n o por cualquier otro medio de difusi&oacute;n o informaci&oacute;n, en el pa&iacute;s o en el extranjero.</p>
        <p>Habr&aacute; actividad inventiva cuando el proceso creativo o sus resultados no se deduzcan del estado de la t&eacute;cnica en forma evidente para una persona normalmente versada en la materia t&eacute;cnica correspondiente.</p>
        <p>Habr&aacute; aplicaci&oacute;n industrial cuando el objeto de la invenci&oacute;n conduzca a la obtenci&oacute;n de un resultado o de un producto industrial, entendiendo al termino industria como comprensivo de la agricultura, le industria forestal, la ganader&iacute;a, la pesca, la miner&iacute;a, las industrias de transformaci&oacute;n propiamente dichas y los servicios.</p>
        <p>Una invenci&oacute;n debe, por lo general, satisfacer las siguientes condiciones para ser protegida por una patente:</p>
        <p>Debe tener un uso pr&aacute;ctico.</p>
        <p>Asimismo, debe presentar un elemento de novedad, es decir, alguna caracter&iacute;stica nueva que no se conozca en el cuerpo de conocimiento existente en su &aacute;mbito t&eacute;cnico.</p>
        <p>Debe presentar un paso inventivo que no podr&iacute;a ser deducido por una persona con un conocimiento medio del &aacute;mbito t&eacute;cnico.</p>
        <p>Su materia debe ser aceptada como "patentable" de conformidad a derecho.</p>
        </div>
        
        <div className="row education">
        <h3>EFECTOS LEGALES Y VENTAJAS DEL REGISTRO DE PATENTES Y MODELOS DE UTILIDAD</h3>
        <p>La <strong>PATENTE</strong> permite al creador de un producto o procedimiento su explotaci&oacute;n por un t&eacute;rmino improrrogable de 20 a&ntilde;os a partir de la presentaci&oacute;n de la solicitud.</p>
        <p>La protecci&oacute;n de una PATENTE significa que la invenci&oacute;n no puede ser confeccionada, utilizada distribuida o vendida comercialmente sin el consentimiento del titular de la patente. El cumplimiento de los derechos de patente normalmente se hace respetar en los tribunales que, en la mayor&iacute;a de los sistemas, tienen la potestad de sancionar las infracciones a la patente.</p>
        <p>El titular de una patente tiene el derecho de decidir quien puede -o no puede- utilizar la invenci&oacute;n patentada durante el per&iacute;odo en el que est&aacute; protegida la invenci&oacute;n. El titular de la patente puede dar su autorizaci&oacute;n, o licencia, a terceros para utilizar la invenci&oacute;n de acuerdo a t&eacute;rminos establecidos de com&uacute;n acuerdo. El titular puede asimismo vender el derecho a la invenci&oacute;n a un tercero, que se convertir&aacute; en el nuevo titular de la patente. Cuando la patente expira, asimismo la protecci&oacute;n y la invenci&oacute;n pasa a pertenecer al dominio p&uacute;blico; es decir el titular deja de detentar derechos exclusivos sobre la invenci&oacute;n, que pasa a estar disponible para la explotaci&oacute;n comercial por parte de terceros.</p>
        <p>La protecci&oacute;n de un <strong>MODELO DE UTILIDAD</strong>, otorga a su titular el derecho a impedir a otros, cualquier forma de explotaci&oacute;n comercial del Modelo de Utilidad, dentro del territorio de obtenci&oacute;n de la protecci&oacute;n y por un periodo de tiempo limitado.</p>
        <p>El <strong>MODELO O DISE&Ntilde;O INDUSTRIAL </strong>es el aspecto ornamental o est&eacute;tico de un art&iacute;culo. El Dise&ntilde;o o Modelo Industrial puede consistir en rasgos en tres dimensiones, como la forma o la superficie de un art&iacute;culo, o rasgos en dos dimensiones, como los dise&ntilde;os, las l&iacute;neas o el color. Los Modelos o Dise&ntilde;os Industriales se aplican a una amplia variedad de productos de la industria y la artesan&iacute;a: desde instrumentos t&eacute;cnicos y m&eacute;dicos a relojes, joyas y otros art&iacute;culos de lujo; desde electrodom&eacute;sticos y aparatos el&eacute;ctricos a veh&iacute;culos y estructuras arquitect&oacute;nicas; desde estampados textiles a bienes recreativos. Para estar protegido por la mayor&iacute;a de las legislaciones nacionales, un dibujo o modelo industrial debe ser no funcional. Esto significa que el car&aacute;cter de un dibujo o modelo industrial es esencialmente est&eacute;tico y la legislaci&oacute;n no protege ninguno de los rasgos t&eacute;cnicos del art&iacute;culo al que se aplica.</p>
        <p><strong>REGISTRABILIDAD</strong></p>
        <p>En la mayor&iacute;a de los pa&iacute;ses, un Modelo o Dise&ntilde;o Industrial debe registrarse a fin de estar protegido por el derecho que rige los Modelos y Dise&ntilde;os Industriales. Por norma general, para poder registrarse, el modelo y dise&ntilde;o industrial debe ser "nuevo" u "original". Los distintos pa&iacute;ses proponen distintas definiciones de dichos t&eacute;rminos, as&iacute; como variaciones en el proceso de registro. Por lo general, "nuevo" significa que no se tiene conocimiento de que haya existido anteriormente un modelo y dise&ntilde;o industrial id&eacute;ntico o muy similar. Cuando se registra un modelo y dise&ntilde;o industrial, se emite un certificado de registro. A partir de este momento, el plazo de protecci&oacute;n es de cinco a&ntilde;os, con la posibilidad de seguir renovando el registro hasta un m&aacute;ximo de 15 a&ntilde;os. Dependiendo de cada legislaci&oacute;n nacional y tipo de modelo y dise&ntilde;o industrial, un dise&ntilde;o o modelo industrial puede asimismo estar protegido como obra de arte en virtud de la legislaci&oacute;n de derecho de autor. En algunos pa&iacute;ses, la protecci&oacute;n de los modelos y dise&ntilde;os industriales y la protecci&oacute;n del derecho de autor pueden existir simult&aacute;neamente. La protecci&oacute;n de los Modelo y Dise&ntilde;o Industriales se limita al pa&iacute;s que concede la protecci&oacute;n.</p>
        </div>
        
        <div className="row education">
        <h3>EFECTOS LEGALES Y VENTAJAS DEL REGISTRO DE MODELOS INDUSTRIALES</h3>
        <p>Los <strong>DISE&Ntilde;OS Y MODELOS INDUSTRIALES</strong> hacen que un producto sea atractivo y atrayente; por consiguiente, aumentan el valor comercial de un producto, as&iacute; como su comerciabilidad. Cuando se protege un modelo o dise&ntilde;o industrial, el titular la persona o entidad que ha registrado el modelo o dise&ntilde;o industrial- goza del derecho exclusivo contra la copia no autorizada o la imitaci&oacute;n del modelo o dise&ntilde;o industrial por parte de terceros. Esto contribuye a que el titular pueda recobrar su inversi&oacute;n. Un sistema eficaz de protecci&oacute;n beneficia asimismo a los consumidores y al p&uacute;blico en general, proporcionando la competencia leal y las pr&aacute;cticas comerciales honestas, alentando la creatividad y promoviendo productos est&eacute;ticamente mas atractivos. La protecci&oacute;n de los modelos o dise&ntilde;os industriales pueden ser relativamente simples y su elaboraci&oacute;n y protecci&oacute;n poco costosa. Son razonablemente accesibles para las peque&ntilde;as y medianas empresas, as&iacute; como para los artistas y artesanos individuales, tanto en los pa&iacute;ses industrializados como en los pa&iacute;ses en desarrollo.</p>
        <p>Un dominio o nombre de dominio es el nombre que identifica un sitio web . Cada dominio tiene que ser &uacute;nico en Internet . Por ejemplo, "www.masadelante.com" es el nombre de dominio de la p&aacute;gina Web de Mas adelante. Un solo servidor Web puede servir m&uacute;ltiples p&aacute;ginas Web de m&uacute;ltiples dominios, pero un dominio s&oacute;lo puede apuntar a un servidor .&nbsp;</p>
        <p>Un dominio se compone normalmente de tres partes: en www.masadelante.com, las tres uves dobles ( WWW ), el nombre de la organizaci&oacute;n (mas adelante) y el tipo de organizaci&oacute;n (com).&nbsp;</p>
        <p>Los tipos de organizaci&oacute;n m&aacute;s comunes son .COM, .NET, .MIL, y .ORG, que se refieren a comercial, network, militar, y organizaci&oacute;n (originalmente sin &aacute;nimo de lucro, aunque ahora cualquier persona puede registrar un dominio .org).&nbsp;</p>
        <p>Puesto que Internet se basa en direcciones IP, y no en nombres de dominio, cada servidor Web requiere de un servidor de nombres de dominio (DNS) para traducir los nombres de los dominios a direcciones IP. Cada dominio tiene un servidor de nombre de dominio primario y otro secundario.&nbsp;</p>
        <p>Por otro lado, si se emplean como simples nombres de dominio no se puede hablar de exclusividad salvo que se actuara como referencia a cierta compa&ntilde;&iacute;a que ha registrado dicho <strong>NOMBRE DE DOMINIO </strong>como marca.&nbsp;</p>
        <p>Los dominios pueden dividirse en dominios de primer y segundo nivel.&nbsp;</p>
        <p>Los dominios de Primer Nivel lo constituyen un grupo de letras desde el final del nombre hasta el primer punto. Estos nombres pueden ser gen&eacute;ricos (tanto abiertos como restringidos, seg&uacute;n sea el tipo de acceso a los mismos), y nacionales (esto es, pertenecientes a un pa&iacute;s).&nbsp;</p>
        <p>Estos dominios se registran en las oficinas del ICANN. Cuando se trata de un nombre de dominio territorial correspondiente a Espa&ntilde;a, de su asignaci&oacute;n se encarga la entidad ES-NIC. No obstante, para registrar un nombre de dominio ".es" es necesario tener en cuenta en primer lugar, que si el nombre no est&aacute; previamente registrado, se le asignar&aacute; a la primera persona que lo solicite. En segundo lugar, que los interesados han de cumplir una serie de estrictos requisitos y determinadas normas de sintaxis y derivaci&oacute;n. Y finalmente, es necesario no incurrir en una serie de prohibiciones ya establecidas.&nbsp;</p>
        <p>Por su parte, los dominios de Segundo Nivel tambi&eacute;n est&aacute;n integrados por n&uacute;meros y letras que preceden al nombre de Primer Nivel. Su asignaci&oacute;n depende de los organismos de cada pa&iacute;s, que se encargan de articular tanto sus normas de funcionamiento como de su administraci&oacute;n.&nbsp;</p>
        <p>Estos dominios se registran a nombre de la persona que figura como titular de los mismos ante el ICANN.</p>
        <p><strong>REGISTRABILIDAD</strong></p>
        <p>Para registrar su marca en INTERNET, en primera instancia existen dos opciones para empresas que desarrollan la actividad comercial. La extensi&oacute;n propiamente de Bolivia, es decir '.com.bo' y la general '.com' que es para empresas que exportan o tienen oficinas comerciales, representantes o distribuidores en otros pa&iacute;ses. La ventaja es importante, ya que una empresa extendida geogr&aacute;ficamente en varios pa&iacute;ses, tendr&aacute; una sola direcci&oacute;n en todo el mundo.&nbsp;</p>
        <p>Otras extensiones son: '.net' para empresas vinculadas a la actividad inform&aacute;tica; '.info' y '.tv' para empresas vinculadas a las comunicaciones; '.org', para organizaciones sin fines de lucro y '.gov' para la actividad p&uacute;blica gubernamental, entre otras.</p>
        </div>
        
        <div className="row education">
        <h3>EFECTOS LEGALES Y VENTAJAS DEL REGISTRO DE NOMBRES DE DOMINIO</h3>
        <p>El registro de la marca en Internet es la direcci&oacute;n que permite utilizar un dominio en <strong>INTERNET </strong>o e-mail. Cada d&iacute;a m&aacute;s empresas peque&ntilde;as, medianas y grandes ya est&aacute;n en la red Internet ofreciendo sus productos y servicios a todo el mundo, conect&aacute;ndose con sus proveedores y clientes, brindando informaci&oacute;n sobre sus productos y servicios. En Internet no existe el derecho de uso, sino que se le concede la marca al primer solicitante.&nbsp;</p>
        <p>En cuanto a derechos de Propiedad Intelectual sobre <strong>NOMBRES DE DOMINIO</strong> se aplica la misma jurisprudencia que protege los signos llamados telef&oacute;nicos y direcciones telegr&aacute;ficas, raz&oacute;n por la que dichos <strong>NOMBRES DE DOMINIO</strong>, adicionalmente a su funci&oacute;n de direcciones, sirven para identificar compa&ntilde;&iacute;as; sin embargo, esto no implica protegerlos como marcas contra cualquier clase de uso fuera de la esfera de Internet con validez mundial y reconoci&eacute;ndoseles prioridad.</p>
        <p>Se reconoce como Lema Comercial toda leyenda o combinaci&oacute;n de palabras destinadas a llamar la atenci&oacute;n del p&uacute;blico sobre productos o servicios determinados con el fin de popularizarlos.</p>
        <p>Los <strong>LEMAS COMERCIALES </strong>son frases u oraciones que sirven para promover y diferenciar un producto, a un prestador de servicios o empresa de otras que realicen la misma actividad; &eacute;stos pueden servir como slogan o frase publicitaria. Asimismo, los <strong>LEMAS COMERCIALES </strong>son frases que, en diversas campa&ntilde;as publicitarias, distinguen un producto inclusive sin mencionar la marca.</p>
        <p><strong>REGISTRABILIDAD</strong></p>
        <p>Se pueden registrar como <strong>LEMAS COMERCIALES </strong>frases u oraciones que sirvan para anunciar productos, establecimientos comerciales, industriales o de servicios, y, adem&aacute;s, ayuden al p&uacute;blico consumidor a distinguirlos f&aacute;cilmente de otros existentes en el mercado. El registro puede ser solicitado por cualquier persona natural o jur&iacute;dica, nacional o extranjera, con capacidad jur&iacute;dica suficiente.</p>
        <p>No constituyen Lemas Comerciales:&nbsp;</p>
        <p><strong>1. </strong>Las palabras o combinaciones que indiquen el g&eacute;nero o describan la calidad del producto o servicio;</p>
        <p><strong>2. </strong>Los que carezcan de originalidad, siempre que se demuestre que hayan sido utilizado anteriormente; y</p>
        <p><strong>3. </strong>Los que incluyan un signo distintivo solicitado o registrado anteriormente por persona distinta.</p>
        </div>
        
        <div className="row education">
        <h3>EFECTOS LEGALES Y VENTAJAS DEL REGISTRO DE LEMAS COMERCIALES</h3>
        <p>Un "LEMA COMERCIAL REGISTRADO" confiere a su titular la propiedad y exclusividad de uso sobre el mismo, convirti&eacute;ndose en un activo intangible, de libre disponibilidad, fundamental para la empresa, con una validez de 10 a&ntilde;os, susceptible a renovaci&oacute;n al concluir ese termino, tiempo durante el cual Miranda &amp; Asociados realizar&aacute; el seguimiento personalizado a su LEMA comercial para mantenerlo a salvo, evitando que terceros pretendan emplearla o registrar un LEMA para promocionar productos similares o id&eacute;nticos; asimismo usted, podr&aacute; crear y lanzar nuevos productos y/o servicios teniendo la certeza de que su LEMA COMERCIAL no tiene similitud con alguno ya registrado o v&aacute;lidamente solicitado.</p>
        <p><strong>DENOMINACI&Oacute;N DE ORIGEN </strong>es el nombre de una regi&oacute;n, provincia, departamento, distrito, localidad o de un &aacute;rea del territorio nacional debidamente registrada que sirve para designar un producto originario de ellos y cuyas cualidades o caracter&iacute;sticas se deban exclusiva o esencialmente al medio geogr&aacute;fico, comprendidos los factores naturales y los factores humanos.&nbsp;</p>
        <p>Con ella se pueden designar productos alimenticios y bebidas, u objetos de artesan&iacute;a elaborados en una regi&oacute;n o zona geogr&aacute;fica espec&iacute;fica.&nbsp;</p>
        <p>Todo industrial, comerciante o prestador de servicios establecidos en un pa&iacute;s, lugar o regi&oacute;n determinada, tiene derecho a usar el nombre geogr&aacute;fico del mismo como indicaci&oacute;n de procedencia de sus productos, mercanc&iacute;as o servicios.</p>
        <p><strong>REGISTRABILIDAD</strong></p>
        <p>Se debe presentar:&nbsp;</p>
        <p><strong>a)</strong> El v&iacute;nculo existente entre los factores naturales y/o humanos que determinan las caracter&iacute;sticas del producto y el medio geogr&aacute;fico.</p>
        <p><strong>b)</strong> El nombre de la Denominaci&oacute;n cuyo registro se solicita.</p>
        <p><strong>c)</strong> La delimitaci&oacute;n del &aacute;rea geogr&aacute;fica a la cual deba aplicarse la Denominaci&oacute;n: antecedentes hist&oacute;ricos, caracter&iacute;sticas generales de la regi&oacute;n, factores clim&aacute;ticos, relieve y naturaleza, homogeneidad de los factores de producci&oacute;n y todo otro dato de inter&eacute;s.</p>
        <p><strong>d)</strong> Los productos para los cuales se usar&aacute; la Denominaci&oacute;n de Origen.</p>
        <p><strong>e)</strong> Descripci&oacute;n detallada del proceso de producci&oacute;n del producto.</p>
        <p><strong>f)</strong> Dem&aacute;s recaudos que establezca la reglamentaci&oacute;n.</p>
        <p>El nombre geogr&aacute;fico que se emplee como indicaci&oacute;n de procedencia o como denominaci&oacute;n de origen, deber&aacute; corresponder exactamente al lugar donde la mercanc&iacute;a adquiri&oacute; su naturaleza o substancia de donde proviene.</p>
        </div>
        
        <div className="row education">
        <h3>EFECTOS LEGALES Y VENTAJAS DEL REGISTRO DE DENOMINACI&Oacute;NES DE ORIGEN&nbsp;</h3>
        <p>La certificaci&oacute;n de origen y calidad son un requisito exigido por los consumidores de mercados extranjeros; por lo tanto y dada la importancia de contar con una ventaja competitiva a favor, que permita enfrentar de mejor modo a la competencia y a estos mercados se hace necesaria la legislaci&oacute;n para todo &aacute;mbito de productos silvoagropecuarios.&nbsp;</p>
        <p>Las ventajas de la <strong>DENOMINACI&Oacute;N DE ORIGEN </strong>son:</p>
        <ul>
        <li>Fomenta y favorece la organizaci&oacute;n del sector productivo.</li>
        <li>Facilita el acceso, de productores, a mercados nacionales e internacionales.</li>
        <li>Mejora a nivel regional, nacional e internacional la divulgaci&oacute;n, promoci&oacute;n y la oferta del producto protegido.</li>
        <li>Proporciona un marco estricto y legal de defensa y protecci&oacute;n del producto contra el fraude.</li>
        <li>Otorga el Derecho de uso de la Indicaci&oacute;n de Procedencia, en forma exclusiva, de la Denominaci&oacute;n de Origen para productos Agr&iacute;colas y Alimentarios y del nombre que la identifica, lo que incluye el derecho al uso de emblemas, distintivos, siglas, logotipos, marbetes, etc., que hayan sido autorizadas por el organismo competente.</li>
        <li>Mejora el Control y garant&iacute;a de calidad especificada en la Denominaci&oacute;n de Origen registrada por la autoridad competente.</li>
        </ul>
        <p>Las <strong>INDICACIONES GEOGR&Aacute;FICAS</strong> son el nombre de una regi&oacute;n, pa&iacute;s o un determinado lugar, el cual es utilizado para designar un producto originario de esa zona geogr&aacute;fica, una de cuyas cualidades o reputaci&oacute;n pueda atribuirse a su entorno geogr&aacute;fico, lo que incluye factores tanto naturales como humanos.</p>
        <p>Las indicaciones geogr&aacute;ficas tienen una larga tradici&oacute;n en el Viejo Mundo: nacieron con la costumbre de designar a los productos con el nombre del lugar de su fabricaci&oacute;n o cosecha. Entre las m&aacute;s antiguas reconocidas se encuentran Roquefort, Borgo&ntilde;a, Champagne, etc. A partir de la Revoluci&oacute;n Industrial, el desarrollo cada vez m&aacute;s intenso del comercio y la demanda de productos reputados, gest&oacute; un conflicto entre quienes deseaban conservar un derecho adquirido gracias a las condiciones clim&aacute;ticas particulares, al tipo de suelos, a las formas de cultivar los productos o fabricarlos, y aqu&eacute;llos que trataban de utilizar denominaciones usurpadas a fin de sacar un provecho ileg&iacute;timo. Esto origin&oacute; las primeras leyes nacionales de pa&iacute;ses europeos y tratados internacionales de protecci&oacute;n. La expresi&oacute;n "Hecho en..." identifica a una indicaci&oacute;n geogr&aacute;fica.</p>
        <p>Para se&ntilde;alar el origen regional de un alimento, bebida o producto industrial partiendo del supuesto de que el origen regional est&aacute; directamente relacionado con el tipo, categor&iacute;a, calidad y forma de elaboraci&oacute;n o fabricaci&oacute;n. Se trata de indicaciones geogr&aacute;ficas que durante muchas d&eacute;cadas han identificado y siguen identificando los productos nucleando a sus productores o fabricantes en asociaciones u organizaciones, todo lo cual le da unas caracter&iacute;sticas muy especiales a este sistema de protecci&oacute;n.</p>
        <p>REGISTRABILIDAD</p>
        <p>Las indicaciones geogr&aacute;ficas pueden protegerse como indicaciones geogr&aacute;ficas propiamente dichas, como apelaciones de origen, como marcas colectivas, como marcas de certificaci&oacute;n, requiriendo todas de registro, o bajo otras formas que no requieran registro, todo depende de las legislaciones nacionales. Las marcas colectivas le pertenecen a asociaciones de productores, las marcas de certificaci&oacute;n exigen que el producto cumpla con determinadas normas, como ser las famosas normas ISO.</p>
        <p>Este g&eacute;nero reconoce varias especies:</p>
        <p>Las indicaciones de procedencia que son neutras respecto de la calidad de los productos que las exhiben.</p>
        <p>Las indicaciones geogr&aacute;ficas que refieren a un nombre geogr&aacute;fico el cual indica la zona de producci&oacute;n de un determinado producto, cuyas caracter&iacute;sticas diferenciales se deben a factores naturales y/o humanos: un producto similar, proveniente de otro lugar o regi&oacute;n, no podr&iacute;a tener las mismas caracter&iacute;sticas, calidad o reputaci&oacute;n.</p>
        <p>Las denominaciones de origen que se encuentran comprendidas en las indicaciones geogr&aacute;ficas calificadas por el hecho de que el v&iacute;nculo entre calidad y origen es m&aacute;s estrecho, y &eacute;sta se debe exclusiva o esencialmente a aqu&eacute;l.</p>
        </div>
        
        <div className="row education">
        <h3>EFECTOS LEGALES Y VENTAJAS DEL REGISTRO DE INDICACIONES GEOGRAFICAS</h3>
        <p>Las Indicaciones Geogr&aacute;ficas Registradas permiten impedir y sancionar el uso de la indicaci&oacute;n geogr&aacute;fica para productos que no sean originarios de la zona de producci&oacute;n. Se protege as&iacute; al consumidor contra las pr&aacute;cticas enga&ntilde;osas y, al ser adoptado como signo distintivo por un grupo de productores de una regi&oacute;n determinada, se reconoce a las indicaciones geogr&aacute;ficas como un objeto protegido por la propiedad intelectual. Esto otorga a los productores el derecho a usar esa IG, as&iacute; como el de excluir a terceros del uso, siempre que ese uso por parte de terceros no habilitados sea susceptible de generar confusi&oacute;n en los consumidores respecto al origen de los productos.</p>
        <p>Se entiende por <strong>NOMBRE COMERCIAL</strong> a cualquier signo que identifique una actividad econ&oacute;mica, empresa o establecimiento mercantil.</p>
        <p>Una empresa o establecimiento podr&aacute; tener m&aacute;s de un nombre comercial. Puede constituirse en <strong>NOMBRE COMERCIAL </strong>de una empresa o establecimiento su denominaci&oacute;n social, raz&oacute;n social u otra designaci&oacute;n inscrita en un registro de personas o sociedades mercantiles.</p>
        <p>Los nombres comerciales son independientes de las denominaciones o razones sociales de personas jur&iacute;dicas, pudiendo ambas coexistir.</p>
        <p>El derecho exclusivo sobre un <strong>NOMBRE COMERCIAL</strong> se adquiere por su primer uso en el comercio y termina cuando cesa el uso del nombre o las actividades de la empresa o establecimiento que lo usa.</p>
        </div>
        
        <div className="row education">
        <h3>EFECTOS LEGALES Y VENTAJAS DEL REGISTRO DE NOMBRES COMERCIALES</h3>
        <p>El titular de un <strong>NOMBRE COMERCIAL</strong> podr&aacute; impedir a cualquier tercero usar en el comercio un signo distintivo id&eacute;ntico o similar cuando ello pudiere causar confusi&oacute;n o un riesgo de asociaci&oacute;n con la empresa del titular, sus productos o servicios; en el caso de <strong>NOMBRES COMERCIALES</strong> notoriamente conocidos, cuando, asimismo, pudiera causarle un da&ntilde;o econ&oacute;mico o comercial, o implicara un aprovechamiento injusto del prestigio del nombre o empresa del titular.</p>
        <p><strong>DERECHOS DE AUTOR </strong>son los derechos que se conceden al creador de una obra literaria, art&iacute;stica, cient&iacute;fica o did&aacute;ctica -llevada a un soporte material- sobre la misma. Entre &eacute;stas encontramos obras escritas, musicales, pinturas, esculturas, reportes cient&iacute;ficos, obras multimedia, manuales, softwares, entre otros.&nbsp;</p>
        <p>Tambi&eacute;n se puede definir los <strong>DERECHOS DE AUTOR </strong>como la facultad exclusiva que posee el creador intelectual para explotar temporalmente, por s&iacute; o por terceros, las obras de su autor&iacute;a y ser reconocido siempre como autor de &eacute;stas con todas las prerrogativas inherentes a dicho reconocimiento, es por ello que cuando se habla sobre la necesidad de llevar una obra a un soporte material se quiere decir que una idea no sirve como argumento; en cambio, llevada a una sinopsis, por ejemplo, ya se puede hablar de un soporte material.&nbsp;</p>
        <p>El derecho de autor tiene diversos aspectos, entre ellos implica o envuelve el derecho de interpretaci&oacute;n o ejecuci&oacute;n p&uacute;blica que significa el derecho a interpretar la obra o ejecutarla en un lugar donde el p&uacute;blico est&aacute; presente o puede estar presente, o aunque no tenga esas condiciones al menos no pertenezca al &aacute;mbito estrictamente dom&eacute;stico o familiar. Puede ser ejecuci&oacute;n en directo o mediante grabaciones.&nbsp;</p>
        <p>Otro aspecto es el derecho de radiodifusi&oacute;n que comprende la emisi&oacute;n por medios inal&aacute;mbricos dirigida a todo aquel que se halle dentro del radio de acci&oacute;n de la se&ntilde;al radiof&oacute;nica y que tengan aparatos para recibir las mismas, sea por radio, televisi&oacute;n, etc.</p>
        <p><strong>REGISTRABILIDAD</strong></p>
        <p>Pueden registrarse: las obras cient&iacute;ficas, literarias y art&iacute;sticas, comprendiendo los escritos de toda naturaleza y extensi&oacute;n; las obras dram&aacute;ticas, composiciones musicales, dram&aacute;tico-musicales; las cinematogr&aacute;ficas, coreogr&aacute;ficas y pantomimitas; las obras de dibujo, pintura, escultura, arquitectura; modelos y obras de arte o ciencia aplicadas a pl&aacute;sticos, fotograf&iacute;as, grabados y discos fonogr&aacute;ficos, en fin, toda producci&oacute;n cient&iacute;fica, literaria, art&iacute;stica o did&aacute;ctica sea cual fuere el procedimiento de reproducci&oacute;n.&nbsp;</p>
        <p>El derecho de autor no es un derecho absoluto, existen ciertas limitaciones y excepciones previstas en las distintas legislaciones y tratados internacionales. En algunos casos se prev&eacute; la "libre utilizaci&oacute;n" que son actos de explotaci&oacute;n de obras que pueden realizarse sin necesidad de permiso del autor. En otros casos, se prev&eacute;n "licencias obligatorias" con la misma consecuencia que en el caso anterior aunque con obligaci&oacute;n de compensar al autor.&nbsp;</p>
        <p>En todos los pa&iacute;ses miembros del llamado Convenio de Berna, la protecci&oacute;n es otorgada a aquel que demuestre ser el autor aunque no haya registrado su obra.</p>
        </div>
        
        <div className="row education">
        <h3>EFECTOS LEGALES Y VENTAJAS DEL REGISTRO DE DERECHOS DE AUTOR</h3>
        <p>Lo m&aacute;s importante es que el titular, autor, puede usar su obra en forma exclusiva, o sea nadie puede utilizarla sin autorizaci&oacute;n del autor. En contra, el autor puede prohibir a terceros la utilizaci&oacute;n de la obra sin su autorizaci&oacute;n. De ah&iacute; que se hable de "derechos exclusivos" para referirse a los derechos de autor.&nbsp;</p>
        <p>Por otra parte, una obra protegida permite la correcta identificaci&oacute;n del autor para evitar el plagio. Esto se llama derechos morales del derecho de autor, uno de cuyos aspectos es poder determinar la "paternidad de la obra". Una obra protegida permite al autor obtener una renta o beneficio por la autorizaci&oacute;n de su uso por terceros.&nbsp;</p>
        <p>La duraci&oacute;n del registro es por toda la vida del autor y varias d&eacute;cadas m&aacute;s, despu&eacute;s de su muerte. De esta forma se beneficia a sus herederos. Los herederos suelen beneficiarse de los derechos de autor entre unos 30 a 50 a&ntilde;os despu&eacute;s de la muerte del autor causahabiente.&nbsp;</p>
        <p>La protecci&oacute;n difiere de los derechos de patente o de modelos industriales que se otorgan por un plazo relativamente corto dado que se pretende que los conocimientos divulgados en sus documentos puedan ser utilizados a nivel industrial por toda la humanidad. Los herederos pueden explotar comercialmente los derechos de autor de su causahabiente tal como lo hubiera hecho &eacute;l.&nbsp;</p>
        <p>El registro ha pasado a ser entonces, de un elemento constitutivo de un derecho, a un elemento probatorio del mismo. De modo que, en general, si un autor ha procedido a registrar su derecho en uno de los pa&iacute;ses miembros del Convenio de Berna, puede considerar que est&aacute; protegido en los restantes pa&iacute;ses miembros sin necesidad de registrar su obra pa&iacute;s por pa&iacute;s. Sin embargo, en algunos casos puede ser necesario el registro en determinado pa&iacute;s extranjero dependiendo de las circunstancias que rodeen cada situaci&oacute;n planteada.</p>
        <p>Los <strong>DERECHOS DE IMAGEN</strong> en particular no est&aacute;n claramente estipulados bajo los <strong>DERECHOS DE AUTOR</strong>. Sin embargo, es posible tener algunas im&aacute;genes registradas como marca comercial para gozar de una mejor protecci&oacute;n. En este sentido, un empresario puede transformar su nombre o rostro (de ser bien conocido) en una marca comercial.</p>
        <ul>
        <li>Protecci&oacute;n de signos distintivos (marcas, lemas), nuevas creaciones y derechos de autor.&nbsp;</li>
        <li>Asesor&iacute;a en contratos de licencia, transferencia de conocimiento, franquicias y colaboraci&oacute;n empresarial.</li>
        <li><strong>PROTECCI&Oacute;N CON P.I.</strong> Patentes. Software. Derecho de Autor. Patente. Derechos de Autor.</li>
        <li><strong>PROTECCI&Oacute;N CON P.I.</strong> Marcas. Secretos Industriales. Modelos de Utilidad y Dise&ntilde;os Industriales Marcas 3d.</li>
        <li><strong>DOCUMENTOS LEGALES.</strong> Estrategia de Secretos Industriales y Contratos de Confidencialidad. Acuerdos de Convivencia. Licencias de Software. Contrato de Licencia de Uso de Marca.</li>
        <li>Competencia Desleal</li>
        <li>Derechos de Autor, Software, Publicidad, Deporte, Entretenimiento y Nuevas Tecnolog&iacute;as</li>
        <li>Definimos estrategias de registro y protecci&oacute;n de marcas y variedades vegetales.</li>
        <li>Asesoramos en protecci&oacute;n de derechos de autor en fotograf&iacute;a, arquitectura, software, y otros.</li>
        <li>Representamos a nuestros clientes en oposiciones, recursos administrativos, acciones judiciales de cancelaci&oacute;n, y de nulidad.</li>
        </ul>
        </div>
   </section>
    );
  }
}

export default Ip;
