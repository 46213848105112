import React, { Component } from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import './App.css';
import ScrollToTop from './ScrollToTop';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Derecho from './Components/Derecho';
import Contacto from './Components/Contacto';
import Nuestra_firma from './Components/Nuestra_firma';
import Portfolio from './Components/Portfolio';
import { NavLink, Switch, Route } from 'react-router-dom';
import Ip from './Pages/Ip';
import Navigation from './Navigation';
import Home from './Pages/Home';
import Tarifario from './Pages/Tarifario';

const Main = (data) => (
      <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/propiedad_intelectual' component={Ip}></Route>
        <Route exact path='/tarifario' component={Tarifario}></Route>
      </Switch>
    );

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      resumeData: {}
    };

    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);

  }

  getResumeData(){
    $.ajax({
      url:'/resumeData.json',
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({resumeData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    this.getResumeData();
  }

  render() {
    return (
      <div className="App">
        <ScrollToTop />
        <Main data={this.state.resumeData}/>
        <Footer data={this.state.resumeData.main} />
      </div>
    );
  }
}

export default App;
