import React, { Component } from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';

class Navigation extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var occupation= this.props.data.occupation;
      var description= this.props.data.description;
      var city= this.props.data.address.city;
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }

    return (
      <navigation id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#inicio" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li><NavLink to='/'>Inicio</NavLink></li>
            <li><NavLink to='/propiedad_intelectual'>Propiedad Intelectual</NavLink></li>
            <li><NavLink to='/tarifario'>Tarifario</NavLink></li>
         </ul>

      </nav>

   </navigation>
    );
  }
}

export default Navigation;
