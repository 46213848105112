import React, { Component } from 'react';

class Clientes extends Component {
  render() {
      
      var bannerpic= "images/bannerpic.jpg";
      var ethelpic= "images/ethelpic.jpg";
      var wilfridopic= "images/wilfridopic.jpg";

    if(this.props.data){
      var testimonials = this.props.data.testimonials.map(function(testimonials){
        return  <li key={testimonials.user}>
            <blockquote>
               <p>{testimonials.text}</p>
               <cite>{testimonials.user}</cite>
            </blockquote>
         </li>
      });
      var profilepic= "images/profilepic.jpg";
    }

    return (
      <section id="clientes">
        <div className="row education">

            <div className="twelve columns header-col">
               <h1><span>Clientes</span></h1>
            </div>
        </div>
      <div className="text-container">
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo1.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo2.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo3.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo4.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo5.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo6.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo7.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo8.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo9.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo10.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo11.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo12.jpeg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo13.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo14.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo15.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo16.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo17.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo18.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo19.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo20.jpeg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo21.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo22.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo23.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo24.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo25.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo26.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo27.jpeg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo28.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo29.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo30.png" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo31.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo32.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo33.png" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo34.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo35.png" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo36.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo37.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo38.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo39.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo40.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo41.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo42.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo43.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo44.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo45.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo46.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo47.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo48.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo49.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo50.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo51.jpeg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo52.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo53.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo54.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo55.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo56.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo57.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo58.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo59.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo60.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo61.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo62.jpeg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo63.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo64.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo65.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo66.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo67.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo68.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo69.jpeg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo70.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo71.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo72.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">

            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo73.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo74.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo75.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo76.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo77.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo78.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo79.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo80.jpg" alt="Jimenez Miketta" /></center>
             </div>
        </div>
        <div className="row">
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo81.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo82.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                <center><img className="pic"  src="images/clientes/logo83.jpg" alt="Jimenez Miketta" /></center>
             </div>
            <div className="three columns container">
                &nbsp;
             </div>
        </div>
    </div>
                            
   </section>
    );
  }
}

export default Clientes;
